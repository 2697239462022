import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import BounceLoader from 'react-spinners/BounceLoader';
import { Amplitude } from '@amplitude/react-amplitude';
import jsonpAdapter from 'axios-jsonp';

import styles from './create.module.scss';
import illustration from '../images/pro.svg';

const ProSubscribe = () => {
  const [result, setResult] = useState(null);

  const handleSubmit = (values, actions) => {
    axios
      .get(process.env.MAILCHIMP_URI, {
        adapter: jsonpAdapter,
        callbackParamName: 'c',
        params: {
          EMAIL: values.email,
          'group[19737][1]': '1',
        },
      })
      .then(() => {
        actions.setSubmitting(false);
        setResult(true);
      });
  };

  return (
    <Amplitude>
      {({ instrument }) => (
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-3 d-none d-lg-block">
              <img src={illustration} class="img-fluid p-4" />
            </div>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={instrument('subscribe pro', handleSubmit)}
              render={({ errors, status, touched, isSubmitting, values }) => (
                <>
                  {result && !isSubmitting && (
                    <div className="col-md-9 text-center">
                      <h1 className="mb-5">Thank you for your interest!</h1>
                      <div className="my-5">We'll be in touch soon :)</div>
                      <div className="my-5">
                        <Link to="/">Go back Home</Link>
                      </div>
                      {status && status.msg && <div>{status.msg}</div>}
                    </div>
                  )}
                  {isSubmitting && (
                    <div className={styles.loading}>
                      <BounceLoader size={20} color={'#ccc'} />
                    </div>
                  )}
                  {!result && !isSubmitting && (
                    <div className="col text-center">
                      <h1>LastMX Pro is currently in private beta</h1>
                      <div className="my-5">
                        Enter your email address below and we'll be in touch
                        shortly.
                      </div>
                      <Form>
                        <div className="form-row align-items-center justify-content-center">
                          <div className="col-md-8">
                            <label className="sr-only" htmlFor="email">
                              Your email
                            </label>
                            <div className="input-group input-group-lg mb-3">
                              <Field
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="your@email.com"
                                required
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-primary text-white text-uppercase"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            {errors.email && touched.email && (
                              <div className={styles.error}>{errors.email}</div>
                            )}
                            {status && status.msg && <div>{status.msg}</div>}
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      )}
    </Amplitude>
  );
};

export default ProSubscribe;
