import React from 'react';
import { LogOnMount } from '@amplitude/react-amplitude';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProSubscribe from '../components/prosubscribe';

const ProPage = () => (
  <Layout nav>
    <SEO title="Pro" />
    <LogOnMount eventType="view pro details" />
    <ProSubscribe />
  </Layout>
);

export default ProPage;
